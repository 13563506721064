import styled from 'styled-components';
import { ICardProps } from '.';

export interface ICardFooter {
  align?: 'right' | 'left' | 'center' | 'space-between' | 'space-around';
}

export const Container = styled.div<ICardProps>`
  padding: 30px 24px;
  border-radius: 5px;
  background-color: #fff;

  border: ${(props) => {
    switch (props.variant) {
      case 'flat':
        return '1px solid #e5e5e5';
      case 'floating':
        return 'none';
      default:
        return 'none';
    }
  }};

  box-shadow: ${(props) => {
    switch (props.variant) {
      case 'flat':
        return 'none';
      case 'floating':
        return '2px 4px 4px rgba(0, 0, 0, 20%)';
      case 'dropdown':
        return '0px 11px 10px rgb(0 0 0 / 35%)';
      default:
        return 'none';
    }
  }};

  @media (max-width: 600px) {
    padding: 20px 16px;
  }
`;

export const CardHeader = styled.div`
  margin-bottom: 30px;

  h5 {
    margin: 0;
    font-family: Roboto, Barlow;
    font-size: 24px;
    font-weight: 500;

    @media (max-width: 600px) {
      font-size: 20px;
    }
  }
`;

export const CardFooter = styled.div<ICardFooter>`
  width: 100%;
  display: flex;
  margin-top: 36px;

  justify-content: ${(props) => {
    switch (props.align) {
      case 'right':
        return 'flex-end';
      case 'left':
        return 'flex-start';
      case 'center':
        return 'center';
      case 'space-between':
        return 'space-between';
      case 'space-around':
        return 'space-around';
      default:
        return 'flex-end';
    }
  }};
`;

export const CardContent = styled.div``;
