import React from 'react';
import { FaRegCreditCard } from 'react-icons/fa';
import { RiBankLine } from 'react-icons/ri';
import { CgProductHunt } from 'react-icons/cg';
import { Hint } from '..';
import {
  formatCurrency,
  insertDocumentMask,
  insertPhoneMask,
} from '../StringHelpers';
import {
  StatusBadgeTable,
  StatusBadgeNotification,
  PayableWithIconsContainer,
} from './styles';

interface ITableText {
  true: string;
  false: string;
}

interface IColumnProps {
  value: string | number | boolean;
}

interface IStatusProps extends IColumnProps {
  tableText?: ITableText;
  type?: 'table' | 'notification';
}

interface ICurrencyProps extends IColumnProps {
  maximumFractionDigits?: number;
}

interface ILargeData {
  cellContent: string;
  limit?: number;
  isObject?: boolean;
}

interface IPayableWithIconsProps {
  payableWith: Payable[] | undefined;
}

type Payable = 'all' | 'credit_card' | 'bank_slip' | 'pix';

const payableTooltipAndIcons = {
  credit_card: {
    tooltip: 'Cartão de Crédito',
    icon: <FaRegCreditCard size={18} opacity="80%" />,
  },
  bank_slip: {
    tooltip: 'Boleto bancário',
    icon: <RiBankLine size={18} opacity="80%" />,
  },
  pix: {
    tooltip: 'PIX',
    icon: <CgProductHunt size={19} opacity="80%" />,
  },
  all: null,
};

const PayableWithIcons: React.FC<IPayableWithIconsProps> = ({
  payableWith,
}) => (
  <>
    {payableWith && payableWith[0] === 'all' ? (
      <PayableWithIconsContainer>
        <Hint tooltip="Cartão de Crédito">
          <FaRegCreditCard size={18} color="var(--black)" opacity="80%" />
        </Hint>
        <Hint tooltip="Boleto bancário">
          <RiBankLine size={18} color="var(--black)" opacity="80%" />
        </Hint>

        <Hint tooltip="PIX">
          <CgProductHunt size={19} color="var(--black)" opacity="80%" />
        </Hint>
      </PayableWithIconsContainer>
    ) : (
      <PayableWithIconsContainer>
        {payableWith?.map((item) => (
          <Hint key={item} tooltip={payableTooltipAndIcons[item]?.tooltip}>
            {payableTooltipAndIcons[item]?.icon}
          </Hint>
        ))}
      </PayableWithIconsContainer>
    )}
  </>
);

const Status: React.FC<IStatusProps> = ({
  value,
  type = 'table',
  tableText = { true: 'Ativo', false: 'Inativo' },
}) => {
  function getStatusText() {
    if (type === 'table') {
      return value ? tableText.true : tableText.false;
    }
    return value ? 'sucesso' : 'erro';
  }

  return type === 'table' ? (
    <StatusBadgeTable status={!!value}>{getStatusText()}</StatusBadgeTable>
  ) : (
    <StatusBadgeNotification status={!!value}>
      {getStatusText()}
    </StatusBadgeNotification>
  );
};

const Document: React.FC<IColumnProps> = ({ value }) => (
  <span>{insertDocumentMask(value.toString())}</span>
);

const Phone: React.FC<IColumnProps> = ({ value }) => (
  <span>{insertPhoneMask(value.toString())}</span>
);

const Currency: React.FC<ICurrencyProps> = ({
  value,
  maximumFractionDigits,
}) => <span>{formatCurrency(+value, maximumFractionDigits)}</span>;

const LargeData = ({
  cellContent,
  limit = 20,
  isObject = false,
}: ILargeData): JSX.Element => {
  function getSubContent() {
    const subStr = cellContent.slice(0, limit);
    return `${subStr}...`;
  }

  function getContent() {
    return !isObject ? cellContent : JSON.stringify(JSON.parse(cellContent));
  }

  try {
    return cellContent && cellContent.length ? (
      <span>
        {cellContent.length <= limit ? (
          <span>{cellContent}</span>
        ) : (
          <Hint tooltip={getContent()}>
            <span>{getSubContent()}</span>
          </Hint>
        )}
      </span>
    ) : (
      <span>-</span>
    );
  } catch (error) {
    return <span />;
  }
};

export { Status, Document, Phone, Currency, LargeData, PayableWithIcons };
