import styled, { css } from 'styled-components';

interface IMenuItemProps {
  isActive: boolean;
}

export const Container = styled.header`
  display: flex;
  padding: 0 100px;
  align-items: center;
  width: 100%;
  height: 80px;
  background: #fff;
  position: fixed;
  top: 0;
  box-shadow: 0 0 4px #3b3a34;
  justify-content: center;
  z-index: 100;

  @media (max-width: 700px) {
    padding: 0;
  }
`;

export const HeaderContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  max-width: 1500px;

  @media (max-width: 700px) {
    width: 100%;
    padding: 0 15px;
    justify-content: space-between;
  }
`;

export const LogoContent = styled.div`
  display: flex;
  align-items: center;

  a {
    img {
      width: 140px;
      height: 52px;
    }
  }

  button {
    display: flex;
    align-items: center;
    background: none;
    border: 0;
    margin-left: 40px;
  }

  @media (max-width: 980px) {
    button {
      display: none;
    }
  }

  @media (max-width: 700px) {
    a {
      img {
        width: 120px;
        height: 45px;
      }
    }
  }
`;

export const Menu = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  margin: 0 2rem;

  ul {
    display: flex;
    width: 100%;
    height: 100%;
    max-width: 650px;
    margin: 0;
    align-items: center;
    list-style: none;
    justify-content: space-around;
  }

  @media (max-width: 980px) {
    display: none;
  }
`;

export const MenuItem = styled.li<IMenuItemProps>`
  display: flex;
  height: 100%;
  width: 90px;
  justify-content: center;
  border-bottom: 6px solid transparent;
  transition: 0.5s;

  ${(props) =>
    props.isActive &&
    css`
      border-bottom: 6px solid #0071bc;
      font-weight: 600;
      opacity: 0.9;
    `}

  > a {
    display: flex;
    align-items: center;
    color: #3b3a34;
    text-decoration: none;
    font-size: 16px;

    &:hover {
      ${(props) =>
        !props.isActive &&
        css`
          font-weight: 600;
          opacity: 0.8;
        `}
    }
  }

  div {
    > button {
      color: #3b3a34;
      background: none;
      border: 0;
      font-size: 16px;

      ${(props) =>
        props.isActive &&
        css`
          font-weight: 600;
          opacity: 0.9;
        `}
    }
  }

  &:hover {
    ${(props) =>
      !props.isActive &&
      css`
        border-bottom: 6px solid #8cc63f;

        div {
          > button {
            font-weight: 600;
            opacity: 0.8;
          }
        }
      `}
  }
`;

export const HeaderActions = styled.div`
  display: flex;
  width: 100%;
  max-width: 320px;
  align-items: center;
  justify-content: flex-end;

  @media (max-width: 1150px) {
    justify-content: center;
  }

  @media (max-width: 980px) {
    display: none;
  }
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
`;

export const ActionItem = styled.div`
  display: flex;
  justify-content: center;

  > div {
    padding: 7px;
    transition: 0.5s;
    border-radius: 50%;

    > button {
      display: flex;
      align-items: center;
      background: transparent;
      border: 0;
      svg {
        opacity: 0.8;
      }
    }
    &:hover {
      background: #ebebeb;
      opacity: 1;
      > button {
        svg {
          opacity: 1;
        }
      }
    }
  }
`;

export const ProfileContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-left: 1rem;
`;

export const Profile = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  transition: 0.5s;
  padding: 8px 16px;
  border-radius: 40px;

  div {
    div {
      button {
        > span {
          font-size: 16px;
          margin-right: 8px;
          font-weight: 600;
          opacity: 0.6;
        }

        img {
          width: 40px;
          height: 40px;
          border-radius: 50%;
        }
      }
    }
  }

  &:hover {
    background: #ebebeb;
    cursor: pointer;

    div {
      div {
        button {
          > span {
            opacity: 0.8;
          }
        }
      }
    }
  }

  @media (max-width: 1150px) {
    padding: 0;

    &:hover {
      background: none;
    }

    div {
      div {
        button {
          > span {
            display: none;
          }
        }
      }
    }
  }
`;

export const HeaderActionsMobile = styled.div`
  display: none;
  align-items: center;

  > button {
    display: flex;
    align-items: center;
    background: none;
    border: 0;
    margin-right: 5px;
  }

  @media (max-width: 980px) {
    display: grid;
    grid-auto-flow: column;
    grid-gap: 0.3rem;
  }
`;
