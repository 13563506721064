import styled from 'styled-components';

export const ContainerCrudHeader = styled.header`
  width: 100%;
  max-width: 1120px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding-bottom: 20px;
  border-bottom: 1px solid rgba(59, 58, 52, 0.2);

  h1 {
    font-size: 24px;
    font-weight: 500;
  }

  @media (max-width: 700px) {
    flex-direction: column;

    h1 {
      font-size: 20px;
      margin-bottom: 1rem;
    }
  }

  @media (max-width: 500px) {
    button {
      font-size: 0px;
      border-radius: 50px;
      svg {
        margin-right: 0px !important;
      }
    }
    h1 {
      font-size: 20px;
    }
  }
`;
