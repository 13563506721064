import styled from 'styled-components';

interface IContainerProps {
  hideFirstAndLastButtons?: boolean;
  hidePreviousAndNextButtons?: boolean;
}

export const Container = styled.div<IContainerProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  flex-wrap: wrap;
  column-gap: 1rem;
  row-gap: 1.5rem;

  ul {
    display: flex;
    column-gap: 8px;

    padding-left: 0px;
    margin-bottom: 0px;

    li {
      list-style: none;
    }

    @media (max-width: 700px) {
      /* obriga a esconder os botões de primeira e última páginas */
      /* mesmo quando a prop hideFirstAndLastButtons dita o contrário */
      ${(props) => {
        if (!props.hideFirstAndLastButtons) {
          return `
          li:first-child, li:last-child {
            display: none;
          }
        `;
        }
        return '';
      }}
    }
  }

  div:last-child {
    display: flex;
    align-items: center;
    column-gap: 8px;
    font-size: 14px;

    select {
      border: none;
      background: rgba(235, 235, 235, 0.5); /*#ebebeb with 50% opacity*/
      height: 32px;
      min-width: 48px;
      padding-left: 8px;
      border-radius: 5px;
      text-align-last: center;
      text-align: center;

      :hover {
        background: #ebebeb;
      }
    }
  }

  @media (max-width: 700px) {
    justify-content: center;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  row-gap: 0.5rem;
`;

export const PageButton = styled.button`
  background: rgba(235, 235, 235, 0.5); /*#ebebeb with 50% opacity*/
  height: 32px;
  width: 32px;
  border: none;
  border-radius: 5px;

  :hover {
    filter: brightness(80%);
  }

  :disabled {
    background: rgba(59, 58, 52, 0.7); //3b3a34 with 70% opacity
    color: white;
  }
`;
