import React, { CSSProperties, ReactNode } from 'react';
import { Container, CardHeader, CardContent, CardFooter } from './styles';

export interface ICardProps {
  variant?: 'floating' | 'flat' | 'dropdown';
  extraStyles?: CSSProperties;
  children?: ReactNode;
}

const CardBase: React.FC<ICardProps> = ({
  extraStyles,
  variant = 'floating',
  children,
}) => {
  return (
    <Container style={extraStyles} variant={variant}>
      {children}
    </Container>
  );
};

export const Card = {
  CardBase,
  CardHeader,
  CardContent,
  CardFooter,
};
