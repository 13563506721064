import React, {
  InputHTMLAttributes,
  useState,
  useCallback,
  useMemo,
  CSSProperties,
} from 'react';
import IntlCurrencyInput from 'react-intl-currency-input';
import InputMask from 'react-input-mask';
import { Hint } from '../Hint';
import { Container, ContainerInput, Label, Error } from './styles';

interface IInputProps extends InputHTMLAttributes<HTMLInputElement> {
  name?: string;
  label?: string;
  formAttributes?: any;
  extraStyles?: CSSProperties;
  inpuType?: 'normal' | 'currency' | 'mask';
  mask?: string;
  maximumFractionDigits?: number;
  hint?: string;
}

export const Input: React.FC<IInputProps> = ({
  name = 'test',
  label,
  formAttributes,
  extraStyles,
  inpuType = 'normal',
  mask = '',
  maximumFractionDigits = 2,
  hint = undefined,
  ...rest
}) => {
  const currencyConfig = {
    locale: 'pt-BR',
    formats: {
      number: {
        BRL: {
          style: 'currency',
          currency: 'BRL',
          minimumFractionDigits: 2,
          maximumFractionDigits,
        },
      },
    },
  };

  const [isFocused, setIsFocused] = useState(false);

  const handleInputFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleInputBlur = useCallback(() => {
    setIsFocused(false);
  }, []);

  const hasError = useMemo(() => {
    return formAttributes
      ? formAttributes.touched[name] && formAttributes.errors[name]
      : false;
  }, [formAttributes, name]);

  const isValid = useMemo(() => {
    return formAttributes
      ? formAttributes.touched[name] && !formAttributes.errors[name]
      : false;
  }, [formAttributes, name]);

  return (
    <Container style={extraStyles}>
      <Label htmlFor={name}>
        {label}
        {hint && <Hint tooltip={hint} />}
      </Label>
      <ContainerInput
        isFocused={isFocused}
        isValid={isValid}
        isErrored={hasError}
      >
        {inpuType === 'normal' ? (
          <input
            name={name}
            onFocus={handleInputFocus}
            onBlur={formAttributes?.handleBlur || handleInputBlur}
            onChange={(event) =>
              formAttributes?.setFieldValue(name, event.target.value)
            }
            {...rest}
          />
        ) : inpuType === 'currency' ? (
          <IntlCurrencyInput
            name={name}
            currency="BRL"
            config={currencyConfig}
            onFocus={handleInputFocus}
            onBlur={formAttributes?.handleBlur || handleInputBlur}
            onChange={(_event: any, value: number) =>
              formAttributes?.setFieldValue(name, value)
            }
            {...rest}
          />
        ) : (
          inpuType === 'mask' && (
            <InputMask
              name={name}
              mask={mask}
              onFocus={handleInputFocus}
              onBlur={formAttributes?.handleBlur || handleInputBlur}
              maskChar=""
              onChange={(e: any) =>
                formAttributes?.setFieldValue(name, e.target.value)
              }
              {...rest}
            />
          )
        )}
      </ContainerInput>
      {hasError && <Error>{formAttributes.errors[name]}</Error>}
    </Container>
  );
};
