import React, { ReactNode } from 'react';
import {
  IModalBaseProps,
  IModalFooterProps,
  IModalHeaderProps,
  Modal,
} from '../Modal';

export interface IModalProps
  extends Omit<IModalBaseProps, 'setIsOpen'>,
    Omit<IModalHeaderProps, 'setIsOpen'>,
    IModalFooterProps {
  hideFooter?: boolean;
  children?: ReactNode;
}

export const ModalBase: React.FC<IModalProps> = ({
  title,
  handleClose,
  isOpen,
  hideFooter = false,
  width,
  children,
  ...rest
}) => (
  <Modal.ModalBase isOpen={isOpen} setIsOpen={handleClose} width={width}>
    <Modal.ModalHeader setIsOpen={handleClose} title={title} />
    {children}
    {!hideFooter && <Modal.ModalFooter handleClose={handleClose} {...rest} />}
  </Modal.ModalBase>
);
