import styled from 'styled-components';

export const ContainerFeedback = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 1.4rem;
  padding: 40px 20px;

  svg {
    margin-bottom: 0.5rem;
  }
`;

export const TextFeedback = styled.h4`
  margin-top: 0.5rem;
  text-align: center;
`;
