import React, { ReactNode } from 'react';
import { FilterDateContainer, SeparatorDate } from './styles';

interface IFilterDateProps {
  messageBottom?: string;
  children?: ReactNode;
}

export const FilterDate: React.FC<IFilterDateProps> = ({
  messageBottom,
  children,
}) => (
  <FilterDateContainer>
    <div>{children}</div>
    {messageBottom && <span>{messageBottom}</span>}
  </FilterDateContainer>
);

export const FilterSeparator: React.FC = () => <SeparatorDate>à</SeparatorDate>;
