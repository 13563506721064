import React, { ButtonHTMLAttributes, CSSProperties } from 'react';
import { BeatLoader } from 'react-spinners';
import { ContainerButton } from './styles';

export type ColorButton =
  | 'black'
  | 'black-outlined'
  | 'blue'
  | 'blue-outlined'
  | 'red'
  | 'red-outlined'
  | 'green'
  | 'green-outlined';

export interface IButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  onClick?: () => void;
  extraStyles?: CSSProperties;
  isLoading?: boolean;
  color?: ColorButton;
}

export const Button: React.FC<IButtonProps> = ({
  onClick,
  color = 'blue',
  extraStyles,
  isLoading = false,
  children,
  ...rest
}) => {
  return (
    <ContainerButton
      style={extraStyles}
      color={color}
      onClick={onClick}
      disabled={isLoading}
      {...rest}
    >
      {!isLoading ? children : <BeatLoader size={9} margin={3} color="#fff" />}
    </ContainerButton>
  );
};
