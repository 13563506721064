import React from 'react';
import { AiOutlineClear, AiOutlineSearch } from 'react-icons/ai';
import { Button } from '../Button';
import { Container } from './styles';

interface IFilterButtonsProps {
  handleFilter: () => void;
  handleClearFilter: () => void;
  disabled?: boolean;
}

export const FilterButtons: React.FC<IFilterButtonsProps> = ({
  handleFilter,
  handleClearFilter,
  disabled = false,
}) => (
  <Container>
    <Button
      color="red-outlined"
      onClick={handleClearFilter}
      extraStyles={{ marginRight: '8px' }}
    >
      <AiOutlineClear />
      Limpar filtros
    </Button>
    <Button disabled={disabled} onClick={handleFilter}>
      <AiOutlineSearch />
      Pesquisar
    </Button>
  </Container>
);
