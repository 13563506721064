import React, { ReactNode } from 'react';
import { Button, Card } from '..';
import { ColorButton } from '../Button';
import { ContainerCrudHeader } from './styles';

interface IBasePageProps {
  titleHeader: ReactNode;
  buttonHeaderContent?: ReactNode | undefined;
  buttonHeaderColor?: ColorButton;
  setOpenAddCrudModal?: (arg: boolean) => void;
  handleSubmit?: (e?: React.FormEvent<HTMLFormElement> | undefined) => void;
  children?: ReactNode;
}

export const BasePage: React.FC<IBasePageProps> = ({
  titleHeader,
  buttonHeaderContent,
  buttonHeaderColor = 'blue',
  setOpenAddCrudModal,
  handleSubmit,
  children,
}) => {
  function handleClick() {
    if (setOpenAddCrudModal) setOpenAddCrudModal(true);
  }

  return (
    <Card.CardBase>
      <Card.CardHeader>
        <ContainerCrudHeader>
          <h1>{titleHeader}</h1>
          {buttonHeaderContent && (
            <Button
              type="button"
              color={buttonHeaderColor}
              onClick={!handleSubmit ? handleClick : handleSubmit}
            >
              {buttonHeaderContent}
            </Button>
          )}
        </ContainerCrudHeader>
      </Card.CardHeader>
      <Card.CardContent>{children}</Card.CardContent>
    </Card.CardBase>
  );
};
