import styled, { css } from 'styled-components';

interface IContainerInputProps {
  isFocused: boolean;
  isValid?: boolean;
  isErrored?: boolean;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  @media (max-width: 1040px) {
    width: 100%;
    max-width: none;
  }
`;

export const Label = styled.label`
  font-size: 14px;
  color: #3b3a34;
  margin-bottom: 5px;

  svg {
    margin-left: 5px;
  }

  @media (max-width: 1335px) {
    font-size: 14px;
  }
`;

export const ContainerInput = styled.div<IContainerInputProps>`
  display: flex;
  align-items: center;
  background: #ebebeb;
  border-radius: 5px;
  padding: 9px 15px;
  border: 2px solid transparent;
  color: transparent;

  ${(props) =>
    props.isFocused &&
    css`
      border-color: #0071bc;
    `}

  ${(props) =>
    props.isValid &&
    css`
      border-color: #8cc63f;
    `}

  ${(props) =>
    props.isErrored &&
    css`
      border-color: #c53030;
    `}

  input {
    width: 100%;
    flex: 1;
    background: transparent;
    border: 0;
    color: #3b3a34;
    font-size: 15px;

    &:focus {
      outline: 0;
    }
  }
`;

export const Error = styled.span`
  font-size: 14px;
  color: #c53030;
  margin: 3px 0 6px;
`;
