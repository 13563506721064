import styled, { css } from 'styled-components';

interface IContainerProps {
  orientation: 'left' | 'right';
}

export const Container = styled.div<IContainerProps>`
  display: flex;
  align-items: flex-end;

  label#label {
    ${(props) =>
      props.orientation === 'right'
        ? css`
            margin-left: 0.6rem;
          `
        : css`
            margin-right: 0.6rem;
          `}
    font-size: 15px;
    cursor: pointer;
    color: #3b3a34;
  }

  .switch {
    position: relative;
    display: inline-block;
    width: 46px;
    height: 21px;
  }

  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .slider:before {
    position: absolute;
    content: '';
    height: 18.5px;
    width: 18.5px;
    left: 1px;
    bottom: 1.4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  input:checked + .slider {
    background-color: #0071bc;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }

  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
`;
