import React, { useState, useEffect, ReactNode } from 'react';

import { Container, Wrapper, PageButton } from './styles';

import { buildPaginationArray } from './helpers';

export interface ITablePaginationProps {
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  limit: number;
  setLimit: React.Dispatch<React.SetStateAction<number>>;
  totalItems: number;
  rowsPerPageOptions?: number[];
  maxPageItems?: number;
  hideFirstAndLastButtons?: boolean;
  hidePreviousAndNextButtons?: boolean;
  loading?: boolean;
  loadingComponent?: ReactNode;
  hideRowsPerPagePagination?: boolean;
}

export const TablePagination: React.FC<ITablePaginationProps> = ({
  page,
  totalItems,
  limit,
  setPage,
  setLimit,
  rowsPerPageOptions = [5, 10, 25, 50],
  maxPageItems = 5,
  hideFirstAndLastButtons = false,
  hidePreviousAndNextButtons = false,
  loading = false,
  loadingComponent = null,
  hideRowsPerPagePagination = false,
}: ITablePaginationProps) => {
  const [totalPages, setTotalPages] = useState(0);

  const [interval, setInterval] = useState({
    begin: 1,
    end: limit,
    total: totalItems,
  });

  useEffect(() => {
    setInterval({
      begin: limit * (page - 1) + 1,
      end: page !== totalPages ? limit * page : totalItems,
      total: totalItems,
    });
    setTotalPages(Math.ceil(totalItems / limit));
  }, [page, totalPages, limit, totalItems]);

  const paginationArray = buildPaginationArray(
    totalItems,
    limit,
    page,
    maxPageItems
  );

  const handleNextPage = () => {
    if (page !== totalPages) setPage((prevState) => prevState + 1);
  };

  const handlePreviousPage = () => {
    if (page > 1) setPage((prevState) => prevState - 1);
  };

  const handleFirstPage = () => {
    if (page !== 1) setPage(1);
  };

  const handleLastPage = () => {
    if (page !== totalPages) setPage(totalPages);
  };

  const handleChangePageSize = (value: number) => {
    setLimit(value);
    setPage(1);
  };

  return (
    <Container>
      <Wrapper>
        <ul>
          {!hideFirstAndLastButtons && (
            <li>
              <PageButton type="button" onClick={handleFirstPage}>
                {'<<'}
              </PageButton>
            </li>
          )}
          {!hidePreviousAndNextButtons && (
            <li>
              <PageButton type="button" onClick={handlePreviousPage}>
                {'<'}
              </PageButton>
            </li>
          )}

          {paginationArray.map((elem) => (
            <li key={elem}>
              <PageButton
                type="button"
                onClick={() => setPage(elem)}
                disabled={elem === page}
              >
                {elem}
              </PageButton>
            </li>
          ))}

          {!hidePreviousAndNextButtons && (
            <li>
              <PageButton type="button" onClick={handleNextPage}>
                {'>'}
              </PageButton>
            </li>
          )}
          {!hideFirstAndLastButtons && (
            <li>
              <PageButton type="button" onClick={handleLastPage}>
                {'>>'}
              </PageButton>
            </li>
          )}
        </ul>
        {loading && <>{loadingComponent}</>}
      </Wrapper>

      <div>
        {!hideRowsPerPagePagination && (
          <select
            onChange={({ target }) =>
              handleChangePageSize(parseInt(target.value, 10))
            }
            value={limit}
          >
            {rowsPerPageOptions.map((option) => (
              <option value={option} key={option}>
                {option}
              </option>
            ))}
          </select>
        )}
        <span>
          Mostrando {interval.begin} à {interval.end} de {interval.total}{' '}
          resultados
        </span>
      </div>
    </Container>
  );
};

TablePagination.defaultProps = {
  maxPageItems: 5,
  rowsPerPageOptions: [5, 10, 25, 50],
  hideFirstAndLastButtons: false,
  hidePreviousAndNextButtons: false,
  loading: false,
  loadingComponent: null,
};
