import React, { useMemo } from 'react';
import { CSSProperties } from 'styled-components';
import { useTable } from 'react-table';

import { Container, TableWrapper, Table, Error } from './styles';

interface IFormTable {
  data: any;
  columns: any[];
  formAttributes?: any;
  formAttributeName?: string;
  extraStyles?: CSSProperties;
  hiddenColumns?: any;
}

export const FormTable = ({
  data = null,
  columns,
  formAttributes,
  formAttributeName,
  extraStyles,
  hiddenColumns = [],
}: IFormTable): JSX.Element => {
  const instance = useTable({
    columns,
    data,
    initialState: { hiddenColumns },
  });

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    instance;

  const isErrored = useMemo(() => {
    return formAttributes && formAttributeName
      ? !!formAttributes.touched[formAttributeName] &&
          !!formAttributes.errors[formAttributeName]
      : false;
  }, [formAttributes, formAttributeName]);

  const isValid = useMemo(() => {
    return formAttributes && formAttributeName
      ? !!formAttributes.touched[formAttributeName] &&
          !formAttributes.errors[formAttributeName]
      : false;
  }, [formAttributes, formAttributeName]);

  return (
    <Container style={extraStyles}>
      <TableWrapper isErrored={isErrored} isValid={isValid}>
        <Table {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => {
                  return (
                    <th
                      {...column.getHeaderProps({
                        style: {
                          minWidth: column.minWidth,
                          maxWidth: column.maxWidth,
                          width: column.width || 70,
                        },
                      })}
                    >
                      {column.render('Header')}
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>

          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </Table>
      </TableWrapper>
      {isErrored && formAttributeName ? (
        <Error>{formAttributes?.errors[formAttributeName]}</Error>
      ) : null}
    </Container>
  );
};
