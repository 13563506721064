import React, { ReactNode, useCallback, useState } from 'react';
import { Offcanvas } from 'react-simple-offcanvas';
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md';
import {
  CanvasConfigs,
  CanvasConfigsItem,
  CanvasContainer,
  CanvasMenu,
  CanvasMenuExpansible,
  CanvasMenuLink,
  ExpandedMenu,
  ExpandedMenuItem,
} from './styles';

interface IMenuLink {
  urlToShowActive: string;
  menuItem: ReactNode;
  hide?: boolean;
}

interface IExpansibleItem {
  id: string;
  menuItem: ReactNode;
  urlToShowActive: string;
  hide?: boolean;
}

interface IMenuExpansible {
  id: string;
  urlToShowActive: string;
  title: string;
  icon?: ReactNode;
  isVisible?: boolean;
  expansiveItens: IExpansibleItem[];
  hide?: boolean;
}

interface IConfig {
  id: string;
  configItem: ReactNode;
  urlToShowActive: string;
  hide?: boolean;
}

export interface IMenuCanvas {
  pathname: string;
  menuLink?: IMenuLink[];
  menuExpansible?: IMenuExpansible[];
  configs?: IConfig[];
}

const MenuCanvas: React.FC<IMenuCanvas> = ({
  pathname,
  menuLink,
  menuExpansible,
  configs,
}) => {
  const [menu, setMenu] = useState<IMenuExpansible[] | undefined>(() =>
    menuExpansible?.map((item) => ({ ...item, isVisible: false }))
  );

  const toggleExpansiveMenu = useCallback((id: string) => {
    setMenu((state) =>
      state?.map((item) => {
        if (item.id === id) {
          return { ...item, isVisible: !item.isVisible };
        }
        return item;
      })
    );
  }, []);

  return (
    <Offcanvas
      allowScroll={false}
      backdrop={false}
      title=""
      position="left"
      styles={{
        width: 230,
        background: '#d3d3d3',
        border: 0,
        padding: 5,
        borderRadius: '0 8px 8px 0',
      }}
    >
      <CanvasContainer>
        <CanvasMenu>
          <strong>Menu</strong>
          <br />
          <ul>
            {menuLink &&
              menuLink.map(
                (item) =>
                  !item.hide && (
                    <CanvasMenuLink
                      key={item.urlToShowActive}
                      isActive={pathname === item.urlToShowActive}
                    >
                      {item.menuItem}
                    </CanvasMenuLink>
                  )
              )}
          </ul>

          <ul>
            {menu &&
              menu.map(
                (item) =>
                  !item.hide && (
                    <CanvasMenuExpansible
                      key={item.id}
                      isActive={pathname.includes(item.urlToShowActive)}
                    >
                      <button
                        type="button"
                        onClick={() => toggleExpansiveMenu(item.id)}
                      >
                        {item.icon}
                        <span>{item.title}</span>
                        {item.isVisible ? (
                          <MdKeyboardArrowUp />
                        ) : (
                          <MdKeyboardArrowDown />
                        )}
                      </button>
                      {item.isVisible && (
                        <ExpandedMenu>
                          {item.expansiveItens.map(
                            (expansiveItem) =>
                              !item.hide && (
                                <ExpandedMenuItem
                                  key={expansiveItem.id}
                                  isActive={
                                    pathname === expansiveItem.urlToShowActive
                                  }
                                >
                                  {expansiveItem.menuItem}
                                </ExpandedMenuItem>
                              )
                          )}
                        </ExpandedMenu>
                      )}
                    </CanvasMenuExpansible>
                  )
              )}
          </ul>
        </CanvasMenu>
        {configs && (
          <>
            <hr />
            <CanvasConfigs>
              <strong>Configurações</strong>
              <br />

              <ul>
                {configs.map(
                  (item) =>
                    !item.hide && (
                      <CanvasConfigsItem
                        key={item.id}
                        isActive={pathname === item.urlToShowActive}
                      >
                        {item.configItem}
                      </CanvasConfigsItem>
                    )
                )}
              </ul>
            </CanvasConfigs>
          </>
        )}
      </CanvasContainer>
    </Offcanvas>
  );
};

export default MenuCanvas;
