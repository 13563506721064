import styled from 'styled-components';

export const LoadingGeneralContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 300px;
`;

export const LoadingTableContent = styled.span`
  span span {
    &:first-child {
      background-color: rgba(0, 113, 188, 0.5); // --blue: #0071BC
    }
    background-color: rgba(140, 198, 63, 0.5); // --green: #8CC63F
    &:last-child {
      background-color: rgba(59, 58, 52, 0.5); // --black: #3B3A34
    }
  }
`;
