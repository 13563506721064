import React from 'react';
import { ClimbingBoxLoader } from 'react-spinners';
import Lottie from 'react-lottie';
import { ContainerFeedback, TextFeedback } from './styles';
import sucessData from './icons/success.json';
import errorData from './icons/error.json';
import { Button } from '../Button';

export interface ICrudFeedbackProps {
  loading: boolean;
  status: 'success' | 'error' | '';
  message: string;
  retryForm?: () => void;
}

export const CrudFeedback: React.FC<ICrudFeedbackProps> = ({
  loading,
  status,
  message,
  retryForm,
}) => {
  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: status === 'success' ? sucessData : errorData,
  };

  return (
    <ContainerFeedback>
      <ClimbingBoxLoader loading={loading} size={13} color="var(--blue)" />
      {loading && <TextFeedback>Por favor, aguarde . . .</TextFeedback>}
      {status === 'success' && !loading && (
        <>
          <Lottie options={defaultOptions} height={300} />
          <TextFeedback>{message}</TextFeedback>
        </>
      )}
      {status === 'error' && !loading && (
        <>
          <Lottie options={defaultOptions} height={300} />
          {message
            .trim()
            .split('. ')
            .map((msg) => (
              <TextFeedback key={msg}>{msg}</TextFeedback>
            ))}

          {retryForm && (
            <Button
              color="red-outlined"
              onClick={retryForm}
              style={{ marginTop: '1rem' }}
            >
              Tentar novamente
            </Button>
          )}
        </>
      )}
    </ContainerFeedback>
  );
};
