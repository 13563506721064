/* eslint-disable no-param-reassign */
function insertCPFmask(cpf: string): string {
  const part1 = cpf.slice(0, 3);
  const part2 = cpf.slice(3, 6);
  const part3 = cpf.slice(6, 9);
  const part4 = cpf.slice(9, 11);

  return `${part1}.${part2}.${part3}-${part4}`;
}

function insertCNPJmask(cnpj: string): string {
  const part1 = cnpj.slice(0, 2);
  const part2 = cnpj.slice(2, 5);
  const part3 = cnpj.slice(5, 8);
  const part4 = cnpj.slice(8, 12);
  const part5 = cnpj.slice(12, 14);

  return `${part1}.${part2}.${part3}/${part4}-${part5}`;
}

/** Recebe uma string e retorna a memsa com o primeiro caracter em caixa alta
 * @param text string a ser formatada
 */
export function formatFirstLetter(text: string): string {
  const first = text.slice(0, 1);
  const rest = text.slice(1);

  return `${first.toLocaleUpperCase()}${rest}`;
}

/** Recebe uma string de documento (CPF/CNPJ) sem máscara e retorna o memso documento (CPF/CNPJ) com máscara
 * @param document documento a ser formatado
 */
export function insertDocumentMask(document: string): string {
  if (document.length === 11) {
    return insertCPFmask(document);
  }
  return insertCNPJmask(document);
}

/** Recebe uma string de documento (CPF/CNPJ) com máscara e retorna o memso documento (CPF/CNPJ) sem máscara
 * @param document documento a ser formatado
 */
export function removeDocumentMask(document: string): string {
  return document.replace(/[-_./]/g, '');
}

/** Recebe uma string de CEP sem máscara e retorna o mesmo CEP com máscara
 * @param cep CEP a ser formatado
 */
export function insertCEPmask(cep: string): string {
  const part1 = cep.slice(0, 5);
  const part2 = cep.slice(5, 8);

  return `${part1}-${part2}`;
}

/** Recebe uma string de CEP com máscara e retorna o memso CEP sem máscara
 * @param cep CEP a ser formatado
 */
export function removeCEPmask(cep: string): string {
  if (cep.search('-') < 0) return cep;

  return cep.replace(/[-]/g, '');
}

/** Recebe uma string de telefone sem máscara e retorna o mesmo telefone com máscara
 * @param phone telefone a ser formatado
 */
export function insertPhoneMask(phone: string): string {
  const ddd = phone.toString().slice(0, 2);
  const number = phone.toString().slice(2);

  return `(${ddd}) ${number}`;
}

/** Recebe uma string de telefone com máscara e retorna o memso telefone sem máscara
 * @param phone telefone a ser formatado
 */
export function removePhoneMask(phone: string): string {
  if (!phone.startsWith('(')) return phone;

  return phone.replace(/\D/g, '');
}

/** Recebe um number correnpondente à um valor monetário e retorna uma string no formato BRL
 * @param value valor a ser formatado
 * @param maximumFractionDigits número máximo de casas após a vírugula (padrão = 2)
 */
export function formatCurrency(
  value: number,
  maximumFractionDigits = 2
): string {
  return Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
    maximumFractionDigits,
  }).format(value);
}

/** Recebe um objeto e retorna uma string no formato de queryParams
 * @param object objeto JSON para ser transformado em queryParam
 */
export function encodeToQueryParams(object: Record<string, unknown>): string {
  const queryParams: string[] = [];

  Object.entries(object).forEach((item) => {
    const [key, value] = item;
    if (
      typeof value === 'string' ||
      typeof value === 'number' ||
      typeof value === 'boolean'
    ) {
      queryParams.push(
        `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
      );
    } else if (Array.isArray(value) && value !== null) {
      value.forEach((relation) => {
        queryParams.push(`${key}[]=${encodeURIComponent(relation)}`);
      });
    }
  });

  return queryParams.join('&');
}

/** Recebe uma string e retorna um booleano correnpondente à validação
 * @param cpf cpf a ser validado
 */
export function validatesCPF(cpf: string): boolean {
  cpf = cpf.replace(/[^\d]+/g, '');
  if (cpf === '') return false;

  if (
    cpf.length !== 11 ||
    cpf === '00000000000' ||
    cpf === '11111111111' ||
    cpf === '22222222222' ||
    cpf === '33333333333' ||
    cpf === '44444444444' ||
    cpf === '55555555555' ||
    cpf === '66666666666' ||
    cpf === '77777777777' ||
    cpf === '88888888888' ||
    cpf === '99999999999'
  ) {
    return false;
  }

  let add = 0;

  for (let i = 0; i < 9; i++) {
    add += parseInt(cpf.charAt(i), 10) * (10 - i);
  }

  let rev = 11 - (add % 11);

  if (rev === 10 || rev === 11) {
    rev = 0;
  }

  if (rev !== parseInt(cpf.charAt(9), 10)) {
    return false;
  }

  add = 0;

  for (let i = 0; i < 10; i++) {
    add += parseInt(cpf.charAt(i), 10) * (11 - i);
  }
  rev = 11 - (add % 11);
  if (rev === 10 || rev === 11) {
    rev = 0;
  }

  if (rev !== parseInt(cpf.charAt(10), 10)) {
    return false;
  }
  return true;
}

/** Recebe uma string e retorna um booleano correnpondente à validação
 * @param cnpj cnpj a ser validado
 */
export function validatesCNPJ(cnpj: string): boolean {
  cnpj = cnpj.replace(/[^\d]+/g, '');

  if (cnpj === '') return false;

  if (cnpj.length !== 14) return false;

  if (
    cnpj === '00000000000000' ||
    cnpj === '11111111111111' ||
    cnpj === '22222222222222' ||
    cnpj === '33333333333333' ||
    cnpj === '44444444444444' ||
    cnpj === '55555555555555' ||
    cnpj === '66666666666666' ||
    cnpj === '77777777777777' ||
    cnpj === '88888888888888' ||
    cnpj === '99999999999999'
  ) {
    return false;
  }

  let tamanho = cnpj.length - 2;

  let numeros = cnpj.substring(0, tamanho);

  const digitos = cnpj.substring(tamanho);

  let soma = 0;

  let pos = tamanho - 7;

  for (let i = tamanho; i >= 1; i--) {
    soma += +numeros.charAt(tamanho - i) * pos--;
    if (pos < 2) {
      pos = 9;
    }
  }

  let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);

  if (resultado.toString() !== digitos.charAt(0)) return false;

  tamanho += 1;

  numeros = cnpj.substring(0, tamanho);

  soma = 0;

  pos = tamanho - 7;

  for (let i = tamanho; i >= 1; i--) {
    soma += +numeros.charAt(tamanho - i) * pos--;
    if (pos < 2) {
      pos = 9;
    }
  }

  resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);

  if (resultado.toString() !== digitos.charAt(1)) return false;

  return true;
}
