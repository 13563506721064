import styled from 'styled-components';

export const LoadingPaginationContainer = styled.span`
  display: flex;
  align-items: center;
  margin-left: 0.5rem;
  column-gap: 0.5rem;
  flex-wrap: wrap;
  p {
    margin: 0;
  }
`;

export const AnimationWrapper = styled.span`
  span span {
    &:first-child {
      background-color: var(--blue);
    }
    background-color: var(--green);
    &:last-child {
      background-color: var(--black);
    }
  }
`;
