import React, { useMemo } from 'react';
import ReactSelect, { Props } from 'react-select';
import { CSSProperties } from 'styled-components';
import { Hint } from '../Hint';
import { ContainerSelect, Error, Label } from './styles';

export interface ISelectOption {
  id: any;
  name: string;
}

type ISelectProps = {
  name?: string;
  label?: string;
  formAttributes?: any;
  extraStyles?: CSSProperties;
  hint?: string;
};

export function Select<
  Option extends ISelectOption,
  IsMulti extends boolean = false
>({
  name,
  label = '',
  formAttributes,
  extraStyles,
  isDisabled = false,
  hint = undefined,
  ...rest
}: ISelectProps & Props<Option, IsMulti>): JSX.Element {
  const hasError = useMemo(() => {
    return formAttributes && name
      ? !!formAttributes.touched[name] && !!formAttributes.errors[name]
      : false;
  }, [formAttributes, name]);

  const isValid = useMemo(() => {
    return formAttributes && name
      ? !!formAttributes.touched[name] && !formAttributes.errors[name]
      : false;
  }, [formAttributes, name]);

  const handleBlur = (value: string | undefined) => {
    if (value) formAttributes?.setFieldTouched(value);
  };

  return (
    <>
      <ContainerSelect
        isValid={isValid}
        isErrored={hasError}
        isDisabled={isDisabled}
        style={extraStyles}
      >
        <Label htmlFor={name}>
          {label}
          {hint && <Hint tooltip={hint} />}
        </Label>
        <ReactSelect
          placeholder="Selecione uma opção"
          noOptionsMessage={() => 'Nenhum resultado encontrado'}
          onBlur={() => handleBlur(name)}
          getOptionLabel={(option) => option.name as string}
          getOptionValue={(option) => option.id}
          className="select-container"
          classNamePrefix="select"
          isDisabled={isDisabled}
          {...rest}
        />
        {hasError && !isDisabled && name ? (
          <Error>{formAttributes?.errors[name]}</Error>
        ) : null}
      </ContainerSelect>
    </>
  );
}

Select.defaultProps = {
  name: '',
  formAttributes: undefined,
  extraStyles: {},
  label: '',
  hint: undefined,
};
