import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  margin: 1.1rem 0;
  justify-content: flex-end;

  @media (max-width: 700px) {
    justify-content: center;
  }
`;
