import styled from 'styled-components';
import { IButtonProps } from '.';

export const ContainerButton = styled.button<IButtonProps>`
  display: flex;
  justify-content: center;
  border-radius: 8px;
  border: 1px solid;
  padding: 12px 20px;
  align-items: center;
  box-shadow: 0px, 2px rgba(0, 0, 0 0.2);
  cursor: pointer;
  font-size: 14px;

  svg:first-child {
    min-height: 16px;
    min-width: 16px;
    margin-right: 4px;
  }

  ${(props) => {
    switch (props.color) {
      case 'blue':
        return `
          background-color: #0071BC;
          color: #fff;
        `;

      case 'blue-outlined':
        return `
          color: #0071BC;
          background-color: #fff;
          border-color: #0071BC;
        `;
      case 'red':
        return `
          background-color: #C53030;
          color: #fff;
        `;

      case 'red-outlined':
        return `
          color: #C53030;
          background-color: #fff;
          border-color: #C53030;
        `;
      case 'black':
        return `
          background-color: #3B3A34;
          color: #fff;
        `;
      case 'black-outlined':
        return `
          color: #3B3A34;
          background-color: #fff;
          border-color: #3B3A34;
        `;
      case 'green':
        return `
          background-color: #8CC63F;
          color: #fff;
        `;
      case 'green-outlined':
        return `
          color: #8CC63F;
          background-color: #fff;
          border-color: #8CC63F;
        `;
      default:
        return `
          display: flex;
          align-items: center;
          border-radius: 8px;
          padding: 11px 20px;
          background-color: #0071BC;
        `;
    }
  }}

  &:not(:disabled):hover {
    filter: brightness(0.95);
  }

  &:disabled {
    opacity: 0.3;
    cursor: not-allowed;
  }
`;
