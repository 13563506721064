/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { CSSProperties, InputHTMLAttributes } from 'react';

import { Container } from './styles';

interface ISwitchProps extends InputHTMLAttributes<HTMLInputElement> {
  name?: string;
  onChange: () => void;
  label?: string;
  labelOrientation?: 'left' | 'right';
  extraStyles?: CSSProperties;
  checked: boolean;
}

export const Switch: React.FC<ISwitchProps> = ({
  name = 'test',
  onChange,
  label = 'Status do registro',
  labelOrientation = 'right',
  checked,
  extraStyles,
  ...rest
}) => {
  return (
    <Container style={extraStyles} orientation={labelOrientation}>
      {labelOrientation === 'left' && (
        <label id="label" htmlFor={name}>
          {label}
        </label>
      )}
      <label className="switch">
        <input
          id={name}
          type="checkbox"
          checked={checked}
          name={name}
          onChange={onChange}
          {...rest}
        />
        <span className="slider round" />
      </label>
      {labelOrientation === 'right' && (
        <label id="label" htmlFor={name}>
          {label}
        </label>
      )}
    </Container>
  );
};
