import React from 'react';
import { FiEdit, FiPower, FiTrash } from 'react-icons/fi';
import { Hint } from '../Hint';

import { Container, TableButton } from './styles';

interface IExtraActions {
  onClickAction?: () => void;
  icon: React.ReactNode;
  backgroundColor?: string;
  tooltip: string;
}

interface IActionsColumnProps {
  hideEdit?: boolean;
  hideDelete?: boolean;
  hideChangeStatus?: boolean;
  extraActions?: IExtraActions[];
  onClickEdit?: () => void;
  onClickDelete?: () => void;
  onClickChangeStatus?: () => void;
}

export const ActionsColumn: React.FC<IActionsColumnProps> = ({
  hideEdit = false,
  hideDelete = false,
  hideChangeStatus = false,
  extraActions,
  onClickEdit,
  onClickDelete,
  onClickChangeStatus,
}) => {
  return (
    <Container>
      {extraActions &&
        extraActions.map((item, index) => (
          <div key={index}>
            <Hint tooltip={item.tooltip}>
              <TableButton
                key={index}
                model="toggle"
                color={item.backgroundColor}
                type="button"
                onClick={item.onClickAction}
              >
                {item.icon}
              </TableButton>
            </Hint>
          </div>
        ))}

      {!hideChangeStatus && (
        <Hint tooltip="Alterar status">
          <TableButton
            model="status"
            type="button"
            onClick={onClickChangeStatus}
          >
            <FiPower color="#3B3A34" size={18} />
          </TableButton>
        </Hint>
      )}

      {!hideEdit && (
        <Hint tooltip="Editar registro">
          <TableButton model="edit" type="button" onClick={onClickEdit}>
            <FiEdit color="#0071BC" size={18} />
          </TableButton>
        </Hint>
      )}

      {!hideDelete && (
        <Hint tooltip="Excluir registro">
          <TableButton model="delete" type="button" onClick={onClickDelete}>
            <FiTrash color="#C53030" size={18} />
          </TableButton>
        </Hint>
      )}
    </Container>
  );
};
