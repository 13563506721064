import styled, { css } from 'styled-components';

interface ICanvasItem {
  isActive: boolean;
}

export const CanvasContainer = styled.div`
  display: flex;
  flex-direction: column;

  strong {
    font-size: 20px;
  }

  hr {
    margin: 1rem 0;
    color: inherit;
    background-color: #3b3a34;
    opacity: 0.3;
  }
`;

export const CanvasMenu = styled.div`
  display: flex;
  flex-direction: column;

  ul {
    list-style: none;
  }
`;

export const CanvasMenuLink = styled.li<ICanvasItem>`
  a {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: #3b3a34;
    font-size: 15px;
    margin-bottom: 15px;

    ${(props) =>
      props.isActive &&
      css`
        font-weight: 600;
      `}
  }

  svg {
    margin-right: 10px;
  }
`;

export const CanvasMenuExpansible = styled.li<ICanvasItem>`
  button {
    display: flex;
    align-items: flex-end;
    background: none;
    border: 0;
    color: #3b3a34;
    font-size: 15px;
    margin-bottom: 15px;

    ${(props) =>
      props.isActive &&
      css`
        font-weight: 600;
      `}

    svg {
      margin-right: 10px;
    }
  }
`;

export const ExpandedMenu = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 15%;
`;

export const ExpandedMenuItem = styled.div<ICanvasItem>`
  margin-bottom: 15px;

  a {
    text-decoration: none;
    font-size: 14px;
    color: #3b3a34;

    ${(props) =>
      props.isActive &&
      css`
        font-weight: 600;
      `}

    svg {
      margin-right: 10px;
    }
  }
`;

export const CanvasConfigs = styled.div`
  display: flex;
  flex-direction: column;

  ul {
    list-style: none;
  }
`;

export const CanvasConfigsItem = styled.li<ICanvasItem>`
  a {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    text-decoration: none;
    color: #3b3a34;
    font-size: 15px;
    margin-bottom: 10px;

    ${(props) =>
      props.isActive &&
      css`
        font-weight: 600;
      `}
  }

  svg {
    margin-right: 10px;
  }
`;
