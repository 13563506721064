import React, { ReactNode } from 'react';
import Tooltip from 'rc-tooltip';
import 'rc-tooltip/assets/bootstrap.css';
import { FaRegQuestionCircle } from 'react-icons/fa';
import { CSSProperties } from 'styled-components';

type ITrigger = 'click' | 'hover' | 'focus';

interface IHintProps {
  tooltip: ReactNode;
  defaultIconSize?: number;
  tooltipMaxWidth?: string;
  tooltipTriggers?: ITrigger[];
  mouseEnterDelay?: number;
  mouseLeaveDelay?: number;
  extraStyles?: CSSProperties;
  children?: ReactNode;
}

export const Hint: React.FC<IHintProps> = ({
  tooltip,
  defaultIconSize = 13,
  tooltipMaxWidth = '10rem',
  tooltipTriggers = ['click', 'hover'],
  mouseEnterDelay = 0.5,
  mouseLeaveDelay = 0.1,
  extraStyles,
  children,
}) => {
  return (
    <span style={extraStyles}>
      <Tooltip
        placement="top"
        trigger={tooltipTriggers}
        overlay={<div style={{ maxWidth: tooltipMaxWidth }}>{tooltip}</div>}
        mouseEnterDelay={mouseEnterDelay}
        mouseLeaveDelay={mouseLeaveDelay}
      >
        {children ? (
          <span>{children}</span>
        ) : (
          <FaRegQuestionCircle
            size={defaultIconSize}
            color="#3b3a34"
            opacity="0.6"
          />
        )}
      </Tooltip>
    </span>
  );
};
