import React, { ReactNode, useMemo } from 'react';
import CreatableSelect, { Props } from 'react-select/creatable';
import { CSSProperties } from 'styled-components';
import { Hint } from '..';
import { ContainerSelect, Error, Label } from './styles';

export interface ISearchableOption {
  id?: any;
  name: ReactNode;
  isNew?: boolean;
}

type ISearchableSelectProps = {
  name: string;
  label?: string;
  formAttributes?: any;
  extraStyles?: CSSProperties;
  onSearchNewOption?: (inputValue: string) => void;
  hint?: string;
};

export function SearchableSelect<
  Option extends ISearchableOption,
  IsMulti extends boolean = false
>({
  name,
  label,
  formAttributes,
  onSearchNewOption,
  extraStyles,
  hint,
  isDisabled = false,
  ...rest
}: ISearchableSelectProps & Props<Option, IsMulti>): JSX.Element {
  const hasError = useMemo(() => {
    return formAttributes
      ? !!formAttributes.touched[name] && !!formAttributes.errors[name]
      : false;
  }, [formAttributes, name]);

  const isValid = useMemo(() => {
    return formAttributes
      ? !!formAttributes.touched[name] && !formAttributes.errors[name]
      : false;
  }, [formAttributes, name]);

  return (
    <ContainerSelect
      isValid={isValid}
      isErrored={hasError}
      isDisabled={isDisabled}
      style={extraStyles}
    >
      <Label htmlFor={name}>
        {label}
        {hint && <Hint tooltip={hint} />}
      </Label>
      <CreatableSelect
        name={name}
        placeholder="Selecione uma opção"
        noOptionsMessage={() => 'Nenhum resultado encontrado'}
        formatCreateLabel={(inputValue) => `Pesquisar por "${inputValue}"`}
        getOptionValue={(option) => option.id ?? ''}
        getOptionLabel={(option) =>
          option.isNew ? option.id ?? '' : (option.name as string)
        }
        getNewOptionData={(inputValue, optionLabel) =>
          ({
            id: optionLabel,
            name: inputValue,
            isNew: true,
          } as Option)
        }
        onBlur={() => {
          formAttributes?.setFieldTouched(name);
        }}
        onCreateOption={onSearchNewOption}
        isDisabled={isDisabled}
        className="select-container"
        classNamePrefix="select"
        {...rest}
      />
      {hasError && !isDisabled ? (
        <Error>{formAttributes?.errors[name]}</Error>
      ) : null}
    </ContainerSelect>
  );
}

SearchableSelect.defaultProps = {
  formAttributes: undefined,
  extraStyles: {},
  label: '',
  onSearchNewOption: null,
  hint: undefined,
};
