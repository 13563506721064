import React, { useState, useEffect, CSSProperties, ReactNode } from 'react';
import ReactModal from 'react-modal';
import { IoMdClose } from 'react-icons/io';
import { FaSave, FaTrash } from 'react-icons/fa';
import { Button, ColorButton } from '../Button';
import { FooterContainer, HeaderContainer } from './styles';

export interface IModalBaseProps {
  isOpen: boolean;
  width?: string;
  setIsOpen?: () => void;
  children?: ReactNode;
}

export interface IModalHeaderProps {
  title?: string;
  setIsOpen: () => void;
}

export interface IModalFooterProps {
  handleClose: () => void;
  handleDelete?: () => void;
  handleConfirm?: (e?: React.FormEvent<HTMLFormElement> | undefined) => void;
  align?: 'right' | 'left' | 'center' | 'space-between' | 'space-around';
  confirmButtonContent?: ReactNode;
  cancelButtonContent?: ReactNode;
  confirmButtonColor?: ColorButton;
  extraFooterStyles?: CSSProperties;
  disableConfirmButton?: boolean;
  hideCancelButton?: boolean;
}

const ModalBase: React.FC<IModalBaseProps> = ({
  children,
  isOpen,
  setIsOpen,
  width = '700px',
}) => {
  const [modalStatus, setModalStatus] = useState(isOpen);

  useEffect(() => {
    setModalStatus(isOpen);
  }, [isOpen]);

  return (
    <ReactModal
      shouldCloseOnOverlayClick={!false}
      ariaHideApp={false}
      onRequestClose={setIsOpen}
      isOpen={modalStatus}
      style={{
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          padding: 30,
          transform: 'translate(-50%, -50%)',
          background: '#fff',
          color: '#3B3A34',
          borderRadius: '5px',
          width: '100%',
          maxWidth: width,
          maxHeight: '96%',
          border: 'none',
        },
        overlay: {
          zIndex: 999,
          backgroundColor: '#181717e6',
        },
      }}
    >
      {children}
    </ReactModal>
  );
};

const ModalHeader: React.FC<IModalHeaderProps> = ({ title, setIsOpen }) => (
  <HeaderContainer>
    <h5>{title}</h5>
    <button type="button" onClick={setIsOpen}>
      <IoMdClose size={20} />
    </button>
  </HeaderContainer>
);

const ModalFooter: React.FC<IModalFooterProps> = ({
  handleClose,
  handleConfirm,
  handleDelete,
  align,
  confirmButtonContent,
  cancelButtonContent,
  confirmButtonColor = 'blue',
  disableConfirmButton = false,
  hideCancelButton = false,
  extraFooterStyles,
}) => (
  <FooterContainer align={align} style={extraFooterStyles}>
    {!hideCancelButton && (
      <Button
        type="button"
        color="black-outlined"
        onClick={handleClose}
        style={{ marginRight: '8px' }}
      >
        {cancelButtonContent || 'Cancelar'}
      </Button>
    )}

    {handleConfirm && (
      <Button
        type="submit"
        disabled={disableConfirmButton}
        color={confirmButtonColor}
        onClick={handleConfirm}
      >
        {confirmButtonContent || (
          <>
            <FaSave /> Salvar
          </>
        )}
      </Button>
    )}

    {handleDelete && (
      <Button type="submit" color="red" onClick={handleDelete}>
        <FaTrash size={14} />
        Excluir
      </Button>
    )}
  </FooterContainer>
);

export const Modal = {
  ModalBase,
  ModalHeader,
  ModalFooter,
};
