import styled, { css } from 'styled-components';

interface IFormRowProps {
  quantity?: number;
}

export const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

export const FormRow = styled.div<IFormRowProps>`
  display: grid;
  grid-template-columns: repeat(
    ${(props) => (props.quantity ? props.quantity : 2)},
    1fr
  );
  column-gap: 1rem;
  row-gap: 1rem;
  margin: 1.1rem 0;

  @media (max-width: 900px) {
    grid-template-columns: 1fr;
  }
`;

export const Filter = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  column-gap: 1rem;
  row-gap: 1rem;
  margin: 1.1rem 0;

  @media (max-width: 900px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: 500px) {
    grid-template-columns: 1fr;
  }
`;

interface IFormLineProps {
  marginTop?: string;
  marginBottom?: string;
}

export const FormLine = styled.hr<IFormLineProps>`
  ${(props) =>
    props.marginTop &&
    css`
      margin-top: ${props.marginTop};
    `}

  ${(props) =>
    props.marginBottom &&
    css`
      margin-bottom: ${props.marginBottom};
    `}

  border: 0;
  height: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
`;
