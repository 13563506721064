/* eslint-disable @typescript-eslint/no-unused-vars */
import { Dispatch, SetStateAction } from 'react';
import { formatFirstLetter } from '../StringHelpers';

interface ICatchErrorParams {
  response: any;
  setLoadingAction?: Dispatch<SetStateAction<boolean>>;
  setErrorMessage?: Dispatch<SetStateAction<string>>;
  setStatus?: Dispatch<SetStateAction<'error' | 'success' | ''>>;
}

function findNestedMessage(message: any): string {
  return typeof message === 'string'
    ? formatFirstLetter(message)
    : findNestedMessage(message[0].message);
}

function handleCatchError({
  response,
  setErrorMessage,
  setLoadingAction,
  setStatus,
}: ICatchErrorParams): string {
  let errorMessage = '';

  if (response.status < 500) {
    try {
      let message = '';
      if (response.data.message.length === 1) {
        message = findNestedMessage(response.data.message);
      } else {
        response.data.message.forEach((item: any) => {
          message = `${findNestedMessage(item.message)}. ${message}`;
        });
      }
      errorMessage = message;
    } catch (e) {
      errorMessage =
        'Ocorreu um erro interno. Por favor, contate um administrador.';
    }
  } else {
    errorMessage =
      'Ocorreu um erro interno. Por favor, contate um administrador.';
  }

  if (setLoadingAction && setStatus && setErrorMessage) {
    setLoadingAction(false);
    setStatus('error');
    setErrorMessage(errorMessage);
  }

  return errorMessage;
}

const statusOptions = [
  { id: '1', name: 'Ativo' },
  { id: '0', name: 'Inativo' },
];

export { handleCatchError, statusOptions };
