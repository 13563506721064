import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: flex-end;

  label {
    margin-left: 0.6rem;
    font-size: 14px;
    cursor: pointer;
    color: #3b3a34;
  }
`;

export const CheckboxInput = styled.input`
  all: unset;
  border: 0.4px solid;
  border-radius: 3px;
  width: 15px;
  height: 15px;
  cursor: pointer;

  &:hover {
    box-shadow: 0px 0px 10px 0 rgb(189, 206, 252);
  }

  &:checked {
    box-shadow: none;
    background: #0071bc;
    border: 0.4px solid transparent;
    margin: 0;
    padding: 0;

    &::before {
      content: '✔';
      color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: -3px;
    }
  }
`;
