import React, { ReactNode } from 'react';
import { FormLine } from '../CommonStyles';
import { Hint } from '../Hint';

import { Header, Title, Subtitle, TitleAndHintContainer } from './styles';

interface IFormSectionProps {
  title: ReactNode;
  subtitle?: ReactNode;
  hint?: ReactNode;
  showTopLine?: boolean;
  showBottomLine?: boolean;
  children?: ReactNode;
}

export const FormSection: React.FC<IFormSectionProps> = ({
  title = undefined,
  subtitle = undefined,
  hint = undefined,
  showTopLine = false,
  showBottomLine = false,
  children,
}) => {
  return (
    <>
      {showTopLine && <FormLine marginTop="1.5rem" marginBottom="1.5rem" />}

      <Header>
        {title && (
          <TitleAndHintContainer>
            <Title>{title}</Title>
            {hint && <Hint tooltip={hint} />}
          </TitleAndHintContainer>
        )}
        {subtitle && <Subtitle>{subtitle}</Subtitle>}
      </Header>

      {children}

      {showBottomLine && <FormLine marginTop="1.5rem" marginBottom="1.5rem" />}
    </>
  );
};
