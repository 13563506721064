import React, { ReactNode } from 'react';
import { CrudFeedback, ICrudFeedbackProps } from '../CrudFeedback';
import { IModalProps, ModalBase } from '../ModalBase';

interface IModalAlertProps extends IModalProps {
  feedbackProps?: ICrudFeedbackProps;
  feedback?: boolean;
  children?: ReactNode;
}

export const ModalAlert: React.FC<IModalAlertProps> = ({
  hideFooter = false,
  feedbackProps,
  feedback,
  children,
  ...rest
}) => (
  <ModalBase hideFooter={hideFooter || feedback} {...rest}>
    {!feedback ? (
      <>{children}</>
    ) : feedbackProps ? (
      <CrudFeedback {...feedbackProps} />
    ) : (
      <></>
    )}
  </ModalBase>
);
