import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  table {
    width: 100%;
    border-spacing: 0;
    tr {
      height: 55px;
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }
    th,
    td {
      margin: 0;
      white-space: nowrap; /* impede a quebra de linha */
      border-bottom: solid 1px #ebebeb;
      /* width: 1%;  cada célula se estica igualmente */
      &.collapse {
        width: 0.0000000001%; // mas o witdh muda quando elas "se chocam"
      }
      &:last-child {
        /* SPAN QUE ESTÁ EM VOLTA DOS BOTÕES DE AÇÕES */
        > span {
          display: flex;
          justify-content: space-evenly;
        }
      }
    }
    th {
      font: 16px 'Roboto', sans-serif;
      color: rgba(59, 58, 52, 0.7); /* var(--black) with 70% */
      text-align: left;
    }
    td {
      padding: 0.5rem;
      font: 16px 'Barlow', 'Roboto';
    }
  }
`;

export const TableWrapper = styled.div`
  max-width: 100%;
  overflow-x: auto;
  overflow-y: auto;
`;
