import styled, { css } from 'styled-components';

interface IButtonTableProps {
  model: 'edit' | 'delete' | 'status' | 'toggle';
  color?: string;
}

export const Container = styled.span`
  width: 50px;
  margin: auto;
`;

export const TableButton = styled.button<IButtonTableProps>`
  border: 0;
  background: none;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;

  &:hover {
    opacity: 0.7;
    color: #fff;

    ${(props) => {
      switch (props.model) {
        case 'edit':
          return css`
            background-color: #0071bc !important;
          `;
        case 'delete':
          return css`
            background-color: #c53030 !important;
          `;
        case 'status':
          return css`
            background-color: #3b3a34 !important;
          `;
        default:
          return css`
            background-color: ${props.color} !important;
          `;
      }
    }}

    svg {
      opacity: 1;
      color: #fff !important;
      font-weight: bold;
    }
  }
`;
