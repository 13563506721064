import React, { ReactNode, useState } from 'react';
import { useTable, useGlobalFilter, Column } from 'react-table';
import { Input } from '../Input';
import { Filter } from '../CommonStyles';
import { Container, TableWrapper } from './styles';

interface ITableProps {
  columns: Column[];
  data: any;
  loading?: boolean;
  loadingComponent?: ReactNode;
  noDataComponent?: ReactNode;
  showGenericFilter?: boolean;
  hiddenColumns?: string[];
}

export const Table: React.FC<ITableProps> = ({
  columns,
  data = [],
  loading = false,
  loadingComponent = null,
  noDataComponent = null,
  showGenericFilter = false,
  hiddenColumns = ['id'],
}: ITableProps) => {
  const [filter, setFilter] = useState<any>();

  const instance = useTable(
    {
      columns,
      data,
      initialState: { hiddenColumns },
    },
    useGlobalFilter
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    setGlobalFilter,
    globalFilteredRows,
  } = instance;

  const handleFilter = (value: any) => {
    setFilter(value);
    setGlobalFilter(value);
  };

  // const useFirstRender = () => {
  //   const ref = useRef(true);
  //   const firstRender = ref.current;
  //   ref.current = false;
  //   return firstRender;
  // };

  // const firstRender = useFirstRender();

  return (
    <Container>
      {showGenericFilter && (
        <Filter>
          <Input
            label="Buscar em todos os campos"
            placeholder="Digite aqui"
            value={filter}
            onChange={({ target: { value } }) => handleFilter(value)}
          />
        </Filter>
      )}
      <TableWrapper>
        <table {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => {
                  return (
                    <th
                      {...column.getHeaderProps({
                        // força a coluna de ações a ter um width fixo
                        // e texto do header da coluna centralizado
                        style: {
                          minWidth:
                            column.id !== 'actionButtons' ? column.minWidth : 0,
                          maxWidth:
                            column.id !== 'actionButtons'
                              ? column.maxWidth
                              : 999999,
                          width:
                            column.id !== 'actionButtons' ? column.width : 70,
                          textAlign:
                            column.id !== 'actionButtons' ? 'left' : 'center',
                          padding:
                            column.id !== 'actionButtons' ? '0.5rem' : '0',
                        },
                      })}
                    >
                      {column.render('Header')}
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          {!loading && (
            <tbody {...getTableBodyProps()}>
              {rows.map((row) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return (
                        <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          )}
        </table>
        {loading && <>{loadingComponent}</>}

        {(!data.length && !loading) /* && !firstRender */ ||
        (!globalFilteredRows.length && !loading) /* && !firstRender */ ? (
          <>{noDataComponent}</>
        ) : null}
      </TableWrapper>
    </Container>
  );
};

Table.defaultProps = {
  loading: false,
  loadingComponent: null,
  noDataComponent: null,
  showGenericFilter: false,
  hiddenColumns: ['id'],
};
