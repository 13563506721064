import styled, { css } from 'styled-components';

interface IContainerSelectProps {
  isValid?: boolean;
  isErrored?: boolean;
  isDisabled?: boolean;
}

export const ContainerSelect = styled.div<IContainerSelectProps>`
  display: flex;
  flex-direction: column;

  label {
    font-size: 14px;
    margin-bottom: 6px;
  }

  ${(props) =>
    props.isValid &&
    css`
      .select__control {
        border-color: #8cc63f !important;
      }
    `}
  ${(props) =>
    props.isErrored &&
    css`
      .select__control {
        border-color: #c53030 !important;
      }
    `}
  ${(props) =>
    props.isDisabled &&
    css`
      .select__control {
        border-color: transparent !important;
      }
    `}

  .select-container {
    .select__control {
      background: #ebebeb;
      border-radius: 5px;
      border: 2px solid transparent;
      color: transparent;
      box-shadow: none;

      .select__value-container {
        font-size: 15px;

        .select__multi-value {
          background-color: rgba(59, 58, 52, 0.1); //3b3a34 with 10% opacity
          font-family: Arial;

          .select__multi-value__label {
            color: rgba(59, 58, 52, 0.7); //3b3a34 with 70% opacity
          }

          .select__multi-value__remove {
            color: rgba(59, 58, 52, 0.5); //3b3a34 with 50% opacity

            &:hover {
              background-color: rgba(59, 58, 52, 0.08); //3b3a34 with 8% opacity
            }
          }
        }
      }

      .select__indicators {
        .select__clear-indicator {
          color: rgba(59, 58, 52, 0.5); //3b3a34 with 50% opacity

          &:hover {
            background-color: rgba(59, 58, 52, 0.08); //3b3a34 with 8% opacity
          }
        }

        .select__dropdown-indicator {
          color: rgba(59, 58, 52, 0.5); //3b3a34 with 50% opacity

          &:hover {
            background-color: rgba(59, 58, 52, 0.08); //3b3a34 with 8% opacity
          }
        }
      }
    }

    .select__control--is-focused {
      border-color: #0071bc;
      box-shadow: none;
    }

    .select__menu {
      border: none;

      .select__menu-list {
        background-color: white;
        border: none;
        border-radius: 5px;

        .select__option--is-selected {
          color: #3b3a34;
          background-color: transparent;
        }

        .select__option--is-focused {
          background-color: #ebebeb;
        }
      }
    }
  }
`;

export const Label = styled.label`
  font-size: 14px;
  color: #3b3a34;
  margin-bottom: 5px;

  @media (max-width: 1335px) {
    font-size: 14px;
  }

  svg {
    margin-left: 5px;
  }
`;

export const Error = styled.span`
  font-size: 14px;
  color: #c53030;
  margin: 3px 0 6px;
`;
