import styled, { css } from 'styled-components';

interface ITableWrapper {
  isErrored?: boolean;
  isValid?: boolean;
}

export const Table = styled.table`
  border-spacing: 0;
  width: 100%;

  th {
    width: 1%;
    font-weight: regular;
    font: 16px 'Roboto', sans-serif;
    color: rgba(59, 58, 52, 0.7); // var(--black) with 70%
    text-align: left;
    padding: 0.5rem;
  }

  td {
    white-space: nowrap;
    padding: 0.125rem 0.5rem;
  }

  tr:hover td {
    background-color: rgba(59, 58, 52, 0.03); // var(--black) with 3%
  }
`;

export const Container = styled.div`
  overflow: hidden;
  height: 100%;
  min-height: 200px;
`;

export const TableWrapper = styled.div<ITableWrapper>`
  overflow: auto;
  border: 1px solid;
  border-color: #ebebeb; // var(--light-grey);
  border-radius: 5px;

  height: calc(100% - 24px);
  // este height deixa um espaço dentro do container
  // para que o componente Error possa ser exibido

  ${(props) =>
    props.isValid &&
    css`
      border-color: #8cc63f; // var(--green);
    `}
  ${(props) =>
    props.isErrored &&
    css`
      border-color: #c53030; // var(--red) !important;
    `}
`;

export const Error = styled.div`
  font-size: 14px;
  color: #c53030; // var(--red);
  margin-top: 6px;
`;
