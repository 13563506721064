import React, {
  CSSProperties,
  InputHTMLAttributes,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { Hint } from '../Hint';
import { Container, ContainerInput, Label, Error } from './styles';

interface IInputProps extends InputHTMLAttributes<HTMLInputElement> {
  name?: string;
  label?: string;
  formAttributes?: any;
  extraStyles?: CSSProperties;
  hint?: string;
}

export const DateInput: React.FC<IInputProps> = ({
  name = 'test',
  label,
  formAttributes,
  extraStyles,
  hint = undefined,
  ...rest
}) => {
  const [isFocused, setIsFocused] = useState(false);

  const hasError = useMemo(() => {
    return formAttributes
      ? formAttributes.touched[name] && formAttributes.errors[name]
      : false;
  }, [formAttributes, name]);

  const isValid = useMemo(() => {
    return formAttributes
      ? formAttributes.getFieldMeta(name).touched &&
          !formAttributes.errors[name]
      : false;
  }, [formAttributes, name]);

  const handleInputFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleInputBlur = useCallback(() => {
    setIsFocused(false);
  }, []);

  return (
    <Container style={extraStyles}>
      {label && (
        <Label htmlFor={name}>
          {label}
          {hint && <Hint tooltip={hint} />}
        </Label>
      )}
      <ContainerInput
        isValid={isValid}
        isErrored={hasError}
        isFocused={isFocused}
      >
        <input
          name={name}
          type="date"
          onFocus={handleInputFocus}
          onBlur={formAttributes?.handleBlur || handleInputBlur}
          onChange={(e: any) => {
            formAttributes?.setFieldValue(name, e.target.value);
          }}
          {...rest}
        />
      </ContainerInput>
      {hasError && <Error>{formAttributes.errors[name]}</Error>}
    </Container>
  );
};
