import styled from 'styled-components';

export const FilterDateContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 49%;
  margin-bottom: 10px;

  div {
    display: flex;
  }

  > span {
    margin-top: 4px;
    font-size: 12px;
  }
`;

export const SeparatorDate = styled.span`
  margin: 45px 7px 0;
`;
