import styled, { css } from 'styled-components';

interface IStatusProps {
  status: boolean;
}

export const StatusBadgeTable = styled.span<IStatusProps>`
  display: flex;
  justify-content: center;
  padding: 7px 16px;
  border-radius: 10px;
  color: #fff;
  font-weight: 600;
  font-size: 14px;
  width: fit-content;

  ${(props) =>
    props.status
      ? css`
          background: #8cc63f;
        `
      : css`
          background: #c53030;
        `}
`;

export const StatusBadgeNotification = styled.div<IStatusProps>`
  opacity: 65%;
  justify-content: center;
  height: 20px;
  text-align: center;
  border-radius: 5px;
  width: 100px;
  color: #fff;

  ${(props) =>
    props.status
      ? css`
          background-color: #8cc63f;
        `
      : css`
          background-color: #c53030;
        `}
`;

export const PayableWithIconsContainer = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 0.7rem;
`;
