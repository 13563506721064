import styled from 'styled-components';

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  column-gap: 2rem;
  align-items: center;

  @media (max-width: 600px) {
    flex-direction: column;

    h5,
    h6 {
      margin-bottom: 0.5rem;
    }
  }
`;

export const TitleAndHintContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  svg {
    margin-left: 12px;
  }
`;

export const Title = styled.h5`
  font-size: 1.125rem;
  font-weight: 500;
`;

export const Subtitle = styled.h6`
  font-size: 1rem;
  font-weight: 400;
`;
