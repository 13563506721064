import React from 'react';
import Lottie from 'react-lottie';
import { LoadingGeneralContainer, LoadingTableContent } from './styles';
import animationData from './loading.json';

interface LoadingGeneralProps {
  height?: number;
}

export const LoadingGeneral: React.FC<LoadingGeneralProps> = ({
  height = 300,
}) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData,
  };
  return (
    <LoadingGeneralContainer>
      <LoadingTableContent>
        <Lottie options={defaultOptions} height={height} />
      </LoadingTableContent>
    </LoadingGeneralContainer>
  );
};
