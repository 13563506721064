import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px;
  text-align: center;
  font-size: 13px;
  font-weight: 650;
  border: 0;
  outline: 0;
  border-radius: 8px;
  transition: 0.3s;

  &:hover {
    opacity: 0.8;
    cursor: pointer;
  }

  svg {
    margin-right: 5px;
  }
`;

export const Label = styled.label`
  font-size: 14px;
  color: #3b3a34;
  margin-bottom: 5px;

  svg {
    margin-left: 5px;
  }

  @media (max-width: 1335px) {
    font-size: 14px;
  }
`;
