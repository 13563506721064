import styled, { css } from 'styled-components';

interface IContainerDropdownProps {
  hideDropdownArrowIndicator: boolean;
}

export const Container = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
`;

export const ToggleDropdown = styled.button`
  display: flex;
  align-items: center;
  background: 0;
  border: 0;
`;

export const ToggleDropdownMenu = styled.div`
  display: flex;
  align-items: flex-end;
`;

export const ContainerDropdown = styled.div<IContainerDropdownProps>`
  position: absolute;
  top: calc(100% + 8px);
  transition: opacity 0.2s ease 0s, visibility 0.2s ease 0s;
  opacity: 1;
  visibility: visible;
  min-width: 200px;
  padding: 25px;
  border-radius: 5px;
  background: #fff;
  box-shadow: 0 7px 16px rgb(59 58 52 / 40%);
  margin-top: 8px;

  &::before {
    content: '';
    position: absolute;
    top: -8px;
    right: calc(50% - 8px);
    width: 0px;
    height: 0px;
    border-style: solid;
    border-width: 0px 8px 8px;
    border-color: transparent transparent #fff;
  }

  ${(props) =>
    props.hideDropdownArrowIndicator &&
    css`
      &::before {
        content: none;
      }
    `}
`;
