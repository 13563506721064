/** Retorna um vetor de inteiros construído a partir de um intervalo:
 * @param start início do intervalo
 * @param end fim do intervalo
 */
export const buildArrayFromInterval = (
  start: number,
  end: number
): number[] => {
  const length = end - start + 1;

  return Array.from({ length }, (_, index) => index + start);
};

/** Constrói o vetor de número de páginas dinamicamente
 * @param totalItems número total de registros na tabela
 * @param limit número de registros por página
 * @param page página que está selecionada atualmente
 * @param maxPageItems total de botões ([1] [2] [3] [4] [5]) para trocar para um número de página específico
 */
export const buildPaginationArray = (
  totalItems: number,
  limit: number,
  page: number,
  maxPageItems: number
): number[] => {
  const MAX_ITEMS = maxPageItems; // quantidade máxima de botões para trocar de pág.
  const MAX_LEFT = (MAX_ITEMS - 1) / 2; // obriga o botão ativo a ficar no meio
  const totalPages = Math.ceil(totalItems / limit);

  const currentPageIsInTheBeginning =
    page <= MAX_LEFT || totalPages < MAX_ITEMS;

  const currentPageIsInTheEnd = page > totalPages - MAX_LEFT;

  let farRightNeighbourIndex = 0;
  let farLeftNeighbourIndex = 0;

  if (currentPageIsInTheBeginning) {
    farRightNeighbourIndex = totalPages <= MAX_ITEMS ? totalPages : MAX_ITEMS;
    farLeftNeighbourIndex = 1;
  } else if (currentPageIsInTheEnd) {
    farRightNeighbourIndex = totalPages;
    farLeftNeighbourIndex = totalPages - MAX_ITEMS + 1;
  } else {
    farRightNeighbourIndex = page + MAX_LEFT;
    farLeftNeighbourIndex = page - MAX_LEFT;
  }

  return buildArrayFromInterval(farLeftNeighbourIndex, farRightNeighbourIndex);
};
