import React, {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';

interface ICrudProviderProps {
  children?: ReactNode;
}
interface ICrudContextData {
  feedback: boolean;
  setFeedback: Dispatch<SetStateAction<boolean>>;
  loadingAction: boolean;
  setLoadingAction: Dispatch<SetStateAction<boolean>>;
  loadingList: boolean;
  setLoadingList: Dispatch<SetStateAction<boolean>>;
  status: StatusType;
  setStatus: Dispatch<SetStateAction<StatusType>>;
  errorMessage: string;
  setErrorMessage: Dispatch<SetStateAction<string>>;
  openAddCrudModal: boolean;
  setOpenAddCrudModal: Dispatch<SetStateAction<boolean>>;
  openEditCrudModal: boolean;
  setOpenEditCrudModal: Dispatch<SetStateAction<boolean>>;
  openDeleteCrudModal: boolean;
  setOpenDeleteCrudModal: Dispatch<SetStateAction<boolean>>;
  openChangeStatusModal: boolean;
  setOpenChangeStatusModal: Dispatch<SetStateAction<boolean>>;
  filter: IFilter;
  setFilter: Dispatch<SetStateAction<IFilter>>;
  tableState: ITableState;
  closeCrudModal: (
    type?: 'create' | 'edit' | 'delete' | 'changeStatus'
  ) => void;
}

interface ITableState {
  page: number;
  limit: number;
  totalItems: number;
  setPage: Dispatch<SetStateAction<number>>;
  setLimit: Dispatch<SetStateAction<number>>;
  setTotalItems: Dispatch<SetStateAction<number>>;
}

interface IFilter {
  [key: string]: string | boolean;
}

type StatusType = 'success' | 'error' | '';

const CrudContext = createContext<ICrudContextData>({} as ICrudContextData);

const CrudProvider: React.FC<ICrudProviderProps> = ({ children }) => {
  const [feedback, setFeedback] = useState(false);
  const [loadingAction, setLoadingAction] = useState(false);
  const [loadingList, setLoadingList] = useState(false);
  const [status, setStatus] = useState<StatusType>('');
  const [errorMessage, setErrorMessage] = useState('');
  const [openAddCrudModal, setOpenAddCrudModal] = useState(false);
  const [openEditCrudModal, setOpenEditCrudModal] = useState(false);
  const [openDeleteCrudModal, setOpenDeleteCrudModal] = useState(false);
  const [openChangeStatusModal, setOpenChangeStatusModal] = useState(false);
  const [filter, setFilter] = useState<IFilter>({});

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalItems, setTotalItems] = useState(0);

  const tableState = useMemo(
    () => ({
      page,
      limit,
      totalItems,
      setPage,
      setLimit,
      setTotalItems,
    }),
    [page, limit, totalItems]
  );

  /** Esta função é destinada a resetar os estados gerais de uma ação de CRUD. As mais usadas são aceitas via parâmetro.
   * Caso queira usá-la para outra ação de CRUD, sendo esta não especificada nos parâmetros aceitos, é necessário apenas
   * chamar a função sem nenhum parâmetro e resetar o possível status booleano da modal correspondente, pois os demais
   * (setFeedback, setLoadingAction e setStatus) já são resetados por padrão.
   * @param type podendo ser: 'create' | 'edit' | 'delete' | 'changeStatus'
   */
  const closeCrudModal = useCallback(
    (type?: 'create' | 'edit' | 'delete' | 'changeStatus') => {
      if (!loadingAction) {
        switch (type) {
          case 'create': {
            setOpenAddCrudModal(false);
            break;
          }
          case 'edit': {
            setOpenEditCrudModal(false);
            break;
          }
          case 'delete': {
            setOpenDeleteCrudModal(false);
            break;
          }
          case 'changeStatus': {
            setOpenChangeStatusModal(false);
            break;
          }
          default:
            break;
        }
        setFeedback(false);
        setLoadingAction(false);
        setStatus('');
      }
    },
    [
      setOpenAddCrudModal,
      setOpenEditCrudModal,
      setOpenDeleteCrudModal,
      setFeedback,
      setLoadingAction,
      setStatus,
      loadingAction,
    ]
  );

  return (
    <CrudContext.Provider
      value={{
        feedback,
        setFeedback,
        loadingAction,
        setLoadingAction,
        loadingList,
        setLoadingList,
        status,
        setStatus,
        errorMessage,
        setErrorMessage,
        tableState,
        openAddCrudModal,
        openDeleteCrudModal,
        openEditCrudModal,
        openChangeStatusModal,
        setOpenAddCrudModal,
        setOpenDeleteCrudModal,
        setOpenEditCrudModal,
        setOpenChangeStatusModal,
        filter,
        setFilter,
        closeCrudModal,
      }}
    >
      {children}
    </CrudContext.Provider>
  );
};

const useCrud = (): ICrudContextData => {
  return useContext(CrudContext);
};

export { CrudProvider, useCrud, IFilter };
