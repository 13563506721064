import React, { ReactNode } from 'react';
import styled from 'styled-components';

interface IRequiredFieldsProps {
  children?: ReactNode;
}

const ContainerMessage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-bottom: 1rem;
`;

export const RequiredFields: React.FC<IRequiredFieldsProps> = ({
  children,
}) => (
  <ContainerMessage>
    <small>(*) Campos obrigatórios</small>
    <small>{children}</small>
  </ContainerMessage>
);
