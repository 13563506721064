import styled from 'styled-components';

export interface IModalFooter {
  align?: 'right' | 'left' | 'center' | 'space-between' | 'space-around';
}

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 36px;

  h5 {
    margin: 0;
    font-family: Roboto, Barlow;
    font-size: 24px;
    font-weight: 500;

    @media (max-width: 600px) {
      font-size: 20px;
    }
  }

  button {
    border: none;
    background: none;
  }
`;

export const FooterContainer = styled.div<IModalFooter>`
  width: 100%;
  display: flex;
  margin-top: 36px;

  justify-content: ${(props) => {
    switch (props.align) {
      case 'right':
        return 'flex-end';
      case 'left':
        return 'flex-start';
      case 'center':
        return 'center';
      case 'space-between':
        return 'space-between';
      case 'space-around':
        return 'space-around';
      default:
        return 'flex-end';
    }
  }};
`;
