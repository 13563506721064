import React, {
  CSSProperties,
  ReactNode,
  useCallback,
  useRef,
  useState,
} from 'react';
import { ImUpload2 } from 'react-icons/im';
import { Container, Button, Label } from './styles';
import { Hint } from '../Hint';

export type ReactFileInputTypes = {
  handleChange?: (file: any) => void;
  acceptedExtensions?: string;
  backgroundColor?: string;
  textColor?: string;
  label?: string;
  text?: string;
  extraInputStyles?: CSSProperties;
  extraContainerStyles?: CSSProperties;
  hint?: string;
  children?: ReactNode;
};

export const FileInput: React.FC<ReactFileInputTypes> = ({
  handleChange,
  acceptedExtensions,
  backgroundColor = '#0071bc',
  textColor = '#fff',
  label,
  text = 'Selecione um arquivo',
  extraInputStyles,
  extraContainerStyles,
  hint = undefined,
  ...props
}) => {
  const [logo, setLogo] = useState<File>();
  const inputFileRef = useRef() as React.MutableRefObject<HTMLInputElement>;

  const handleClick = useCallback(() => {
    if (inputFileRef && inputFileRef.current) {
      inputFileRef.current.click();
    }
  }, []);

  const handleChangeFile = useCallback(
    (event: any) => {
      const fileUploaded = event.target?.files[0];
      setLogo(fileUploaded);
      if (handleChange) {
        handleChange(fileUploaded);
      }
    },
    [handleChange]
  );

  return (
    <Container style={extraContainerStyles}>
      <Label>
        {label}
        {hint && <Hint tooltip={hint} />}
      </Label>
      <Button
        type="button"
        onClick={handleClick}
        style={Object.assign(extraInputStyles || {}, {
          background: backgroundColor,
          color: textColor,
        })}
      >
        <ImUpload2 size={16} color={textColor} />
        {!logo ? text : logo?.name}
      </Button>
      <input
        type="file"
        accept={acceptedExtensions}
        ref={inputFileRef}
        style={{ display: 'none' }}
        onChange={handleChangeFile}
        {...props}
      />
    </Container>
  );
};
