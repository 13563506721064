import React, { ReactNode } from 'react';

import { GiSniffingDog } from 'react-icons/gi';
import { LoadingGeneralContainer } from '../LoadingGeneral/styles';

export const NoDataInTable: React.FC<{ extraInfo?: ReactNode }> = ({
  extraInfo,
}) => (
  <LoadingGeneralContainer>
    <GiSniffingDog size={50} color="gray" /> <br />
    <span>Nada encontrado por aqui...</span>
    {extraInfo && <span>{extraInfo}</span>}
  </LoadingGeneralContainer>
);
