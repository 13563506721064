import React from 'react';
import { PulseLoader } from 'react-spinners';
import { AnimationWrapper, LoadingPaginationContainer } from './styles';

export const LoadingInsidePagination: React.FC = () => {
  return (
    <LoadingPaginationContainer>
      <AnimationWrapper>
        <PulseLoader size={10} />
      </AnimationWrapper>
      <p>Carregando...</p>
    </LoadingPaginationContainer>
  );
};
