import React from 'react';

interface IAvatarProps {
  hasAvatar: boolean;
  defaultImage: string;
  imageUrl: string;
}

export const UserAvatar = ({
  hasAvatar,
  defaultImage,
  imageUrl,
}: IAvatarProps): JSX.Element => {
  const userAvatar = hasAvatar ? imageUrl : defaultImage;

  return <img src={userAvatar} alt={hasAvatar ? 'user avatar' : 'no avatar'} />;
};
