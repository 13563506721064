import React, {
  CSSProperties,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md';
import {
  Container,
  ToggleDropdown,
  ContainerDropdown,
  ToggleDropdownMenu,
} from './styles';

export interface IDropdownProps {
  toggle: ReactNode;
  right?: string;
  extraDropdownStyles?: CSSProperties;
  extraToggleStyles?: CSSProperties;
  dropType?: 'menu' | 'generic';
  triggerToClose?: string;
  hideDropdownArrowIndicator?: boolean;
  children?: ReactNode;
}

export const Dropdown: React.FC<IDropdownProps> = ({
  right,
  extraDropdownStyles,
  extraToggleStyles,
  toggle,
  dropType = 'generic',
  triggerToClose,
  hideDropdownArrowIndicator = false,
  children,
}) => {
  const container = React.createRef<HTMLDivElement>();
  const [showDropdown, setShowDropdown] = useState(false);
  const [firstTrigger, setFirstTrigger] = useState<string>();

  function toggleDropdown(): void {
    setShowDropdown(!showDropdown);
  }

  const handleClickOutside = useCallback(
    (event: any) => {
      if (container.current && !container.current.contains(event.target)) {
        setShowDropdown(false);
      }
    },
    [container]
  );

  useEffect(() => {
    if (!firstTrigger) setFirstTrigger(triggerToClose);
  }, [firstTrigger, triggerToClose]);

  useEffect(() => {
    if (triggerToClose !== firstTrigger) setShowDropdown(false);
  }, [firstTrigger, triggerToClose]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
  }, [handleClickOutside]);

  return (
    <Container ref={container}>
      <ToggleDropdown onClick={toggleDropdown} style={extraToggleStyles}>
        {dropType === 'menu' && (
          <ToggleDropdownMenu>
            {toggle}{' '}
            {showDropdown ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown />}
          </ToggleDropdownMenu>
        )}
        {dropType === 'generic' && toggle}
      </ToggleDropdown>
      {showDropdown && (
        <ContainerDropdown
          hideDropdownArrowIndicator={hideDropdownArrowIndicator}
          style={Object.assign(extraDropdownStyles || {}, { right })}
        >
          {children}
        </ContainerDropdown>
      )}
    </Container>
  );
};
