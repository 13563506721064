import React, { ReactNode, useCallback, useMemo, useState } from 'react';
import { OffcanvasProvider, Trigger } from 'react-simple-offcanvas';
import { FiMenu } from 'react-icons/fi';
import {
  ActionItem,
  Actions,
  Container,
  HeaderActions,
  HeaderActionsMobile,
  HeaderContent,
  Menu,
  MenuItem,
  ProfileContainer,
  Profile,
  LogoContent,
} from './styles';
import MenuCanvas, { IMenuCanvas } from './MenuCanvas';

export interface IMenu {
  type: 'link' | 'dropdown';
  urlToShowActive: string;
  menuItem: ReactNode;
  hide?: boolean;
}

interface IAction {
  id: string;
  actionItem: ReactNode;
  showInDesktop: boolean;
  showInMobile: boolean;
}

interface IProfile {
  id: string;
  profileItem: ReactNode;
}

export interface IHeaderProps {
  menuType?: 'top' | 'aside';
  pathname: string;
  logoComponent: ReactNode;
  menu: IMenu[];
  actions: IAction[];
  profile: IProfile;
  canvasMenu: IMenuCanvas;
}

export const GenericHeader: React.FC<IHeaderProps> = ({
  menuType = 'top',
  pathname,
  logoComponent,
  menu,
  actions,
  profile,
  canvasMenu,
}) => {
  const getMenuItemStatus = useCallback(
    (type: string, url: string): boolean => {
      if (type === 'link') {
        return pathname === url;
      }
      return pathname.includes(url);
    },
    [pathname]
  );

  const canOpenCanvasMenu = useMemo<boolean>(() => {
    const menuLength = menu.length;
    const canvasMenuLinkLength = canvasMenu.menuLink?.length;
    const canvasmenuExpansibleLength = canvasMenu.menuExpansible?.length;
    const canvasConfigsLength = canvasMenu.configs?.length;

    const menuHiddenQuantity = menu.filter((item) => item.hide).length;
    const canvasMenuLinkHiddenQuantity = canvasMenu.menuLink?.filter(
      (item) => item.hide
    ).length;
    const canvasMenuExpansibleHiddenQuantity =
      canvasMenu.menuExpansible?.filter((item) => item.hide).length;
    const canvasConfigsHiddenQuantity = canvasMenu.configs?.filter(
      (item) => item.hide
    ).length;

    return !(
      menuLength === menuHiddenQuantity &&
      canvasMenuLinkLength === canvasMenuLinkHiddenQuantity &&
      canvasmenuExpansibleLength === canvasMenuExpansibleHiddenQuantity &&
      canvasConfigsLength === canvasConfigsHiddenQuantity
    );
  }, [
    canvasMenu.configs,
    canvasMenu.menuExpansible,
    canvasMenu.menuLink,
    menu,
  ]);

  const [widthScreen, setWidthScreen] = useState(window.innerWidth);

  function calculeteWidth() {
    setWidthScreen(window.innerWidth);
  }

  window.addEventListener('resize', calculeteWidth);

  return (
    <OffcanvasProvider>
      <Container>
        <HeaderContent>
          <LogoContent>
            {logoComponent}
            {menuType === 'aside' && canOpenCanvasMenu && (
              <Trigger>
                <FiMenu size={22} />
              </Trigger>
            )}
          </LogoContent>

          {menuType === 'top' && (
            <Menu>
              <ul>
                {menu.map(
                  (item) =>
                    !item.hide && (
                      <MenuItem
                        key={item.urlToShowActive}
                        isActive={getMenuItemStatus(
                          item.type,
                          item.urlToShowActive
                        )}
                      >
                        {item.menuItem}
                      </MenuItem>
                    )
                )}
              </ul>
            </Menu>
          )}

          {widthScreen > 980 ? (
            <HeaderActions>
              <Actions>
                {actions.map((item) => {
                  return (
                    item.showInDesktop && (
                      <ActionItem key={item.id}>{item.actionItem}</ActionItem>
                    )
                  );
                })}
              </Actions>

              <ProfileContainer>
                <Profile>{profile.profileItem}</Profile>
              </ProfileContainer>
            </HeaderActions>
          ) : (
            <HeaderActionsMobile>
              {canOpenCanvasMenu && (
                <Trigger>
                  <FiMenu size={22} />
                </Trigger>
              )}
              {actions.map((item) => {
                return (
                  item.showInMobile && (
                    <React.Fragment key={item.id}>
                      {item.actionItem}
                    </React.Fragment>
                  )
                );
              })}
              <ProfileContainer>
                <Profile>{profile.profileItem}</Profile>
              </ProfileContainer>
            </HeaderActionsMobile>
          )}
        </HeaderContent>
      </Container>
      <MenuCanvas {...canvasMenu} />
    </OffcanvasProvider>
  );
};
